import { Controller } from '@hotwired/stimulus'

// Performs dynamic password validation on the password form.
// Connects to data-controller="password-check"
export default class extends Controller {
  static targets = [
    'inputPassword',
    'inputPasswordConfirmation',
    'chkCharLength',
    'chkLowerCaseLetter',
    'chkUpperCaseLetter',
    'chkNumber',
    'chkSymbol',
    'chkPasswordsMatch',
    'submitButton'
  ]

  validate (event) {
    let valid = true

    // Check password length.
    if (this.#hasEightCharacters(this.inputPasswordTarget.value)) {
      this.#fulfilled(this.chkCharLengthTarget)
    } else {
      valid = false
      this.#unfulfilled(this.chkCharLengthTarget)
    }

    // Check that a lowercase letter is present.
    if (this.#hasLowerCase(this.inputPasswordTarget.value)) {
      this.#fulfilled(this.chkLowerCaseLetterTarget)
    } else {
      valid = false
      this.#unfulfilled(this.chkLowerCaseLetterTarget)
    }

    // Check that an uppercase letter is present.
    if (this.#hasUpperCase(this.inputPasswordTarget.value)) {
      this.#fulfilled(this.chkUpperCaseLetterTarget)
    } else {
      valid = false
      this.#unfulfilled(this.chkUpperCaseLetterTarget)
    }

    // Check that a symbol is present.
    if (this.#hasSymbol(this.inputPasswordTarget.value)) {
      this.#fulfilled(this.chkSymbolTarget)
    } else {
      valid = false
      this.#unfulfilled(this.chkSymbolTarget)
    }

    // Check that a number is present.
    if (this.#hasNumber(this.inputPasswordTarget.value)) {
      this.#fulfilled(this.chkNumberTarget)
    } else {
      valid = false
      this.#unfulfilled(this.chkNumberTarget)
    }

    // Check that the two passwords match.
    if (this.#hasMatch(this.inputPasswordTarget.value, this.inputPasswordConfirmationTarget.value)) {
      this.#fulfilled(this.chkPasswordsMatchTarget)
    } else {
      valid = false
      this.#unfulfilled(this.chkPasswordsMatchTarget)
    }

    if (valid) {
      this.#unlockSubmitButton()
    } else {
      this.#lockSubmitButton()
    }
  }

  #hasEightCharacters (str) {
    return str.length >= 8
  }

  #hasLowerCase (str) {
    return str.toUpperCase() !== str
  }

  #hasUpperCase (str) {
    return str.toLowerCase() !== str
  }

  #hasSymbol (str) {
    return (/[-!$%^&*()_@#<>[\]{}?+=|\\/,.;:~]/.test(str))
  }

  #hasNumber (str) {
    return (/[0-9]/.test(str))
  }

  #hasMatch (str1, str2) {
    return str1.length > 0 && str2.length > 0 && str1 === str2
  }

  #unlockSubmitButton () {
    this.submitButtonTarget.disabled = false
  }

  #lockSubmitButton () {
    this.submitButtonTarget.disabled = true
  }

  #fulfilled (el) {
    el.classList.add('fa-check')
    el.classList.remove('fa-circle')
  }

  #unfulfilled (el) {
    el.classList.add('fa-circle')
    el.classList.remove('fa-check')
  }
}
