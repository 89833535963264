import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="toggle-class"
export default class extends Controller {
  static classes = ['css']
  static targets = ['el']

  toggle () {
    this.elTargets.forEach((el) => {
      el.classList.toggle(...this.cssClasses)
    })
  }
}
