import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ['form', 'input', 'results', 'result']

  connect () {
    document.addEventListener('click', (event) => {
      if (!this.element.contains(event.target)) {
        this.removeResults()
      }
    })

    // Index of the currently selected option.
    this.index = -1
  }

  getResults () {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if (this.inputTarget.value.length === 0) {
        this.removeResults()
      } else {
        this.formTarget.requestSubmit()
        this.showResults()
      }
    }, 250)
  }

  selectResult (event) {
    event.preventDefault()

    const selected = this.resultTargets[this.index]
    if (selected) {
      selected.querySelector('a').click()
    }
  }

  resultClicked (event) {
    this.resultWasClicked = this.element.contains(event.target)
  }

  removeResults () {
    if (!this.resultWasClicked) {
      this.resultsTarget.innerHTML = ''
    }
    this.resultWasClicked = false
  }

  showResults () {
    this.resultsTarget.classList.remove('hidden')
  }

  // Update the index to the targeted element -- this is used when hovering over a result.
  updateIndex (event) {
    this.#unselect()
    this.index = Number(event.target.closest('.landing-company-result').dataset.index)
    this.#select()
  }

  // Navigate up in the results list. If we are at the first element, stay there.
  navUp (event) {
    // NOTE: Prevent default to avoid browser scrolling when using the down arrow key.
    event.preventDefault()

    this.#unselect()

    if (this.index >= 1) {
      this.index = this.index - 1
    } else {
      this.index = 0
    }

    this.#select()
  }

  // Navigate down in the results list. If we are at the last element, stay there.
  navDown (event) {
    // NOTE: Prevent default to avoid browser scrolling when using the down arrow key.
    event.preventDefault()

    this.#unselect()

    if (this.index >= this.resultTargets.length - 1) {
      this.index = this.resultTargets.length - 1
    } else {
      this.index = this.index + 1
    }

    this.#select()
  }

  // Selects a new result row.
  // - Add the 'selected' class
  // - Scroll into view
  #select () {
    const el = this.resultTargets[this.index]

    if (el) {
      el.classList.add('selected')
      el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
  }

  // Unselect a result row.
  // - Remove the 'selected' class
  #unselect () {
    const el = this.resultTargets[this.index]

    if (el) {
      el.classList.remove('selected')
    }
  }
}
