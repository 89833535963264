import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="delayed-redirect"
export default class extends Controller {
  static values = {
    url: {
      type: String
    },
    delayMs: {
      type: Number,
      default: 3000
    }
  }

  connect () {
    setTimeout(() => {
      Turbo.visit(this.urlValue)
    }, this.delayMsValue)
  }
}
