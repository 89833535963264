import { Controller } from '@hotwired/stimulus'

// NOTE: Need to include 'followCursor' since it is a plugin https://atomiks.github.io/tippyjs/v6/plugins/
import tippy, { followCursor } from 'tippy.js'

// Connects to data-controller="tooltip"
export default class extends Controller {
  static targets = ['trigger', 'content']
  static values = {
    // Where to place the tooltip relative to its element.
    // Ref: https://atomiks.github.io/tippyjs/v6/all-props/#placement
    placement: { type: String, default: 'top' },

    // Show an error at the edge of the tooltip that points to its element.
    arrow: { type: Boolean, default: false },

    // Transition duration format is: [show duration, hide duration] (in ms)
    // Ref: https://atomiks.github.io/tippyjs/v6/all-props/#duration
    duration: { type: Array, default: [300, 250] },

    // Define the animation we want to use for tippy. The tippy default is fade.
    // Ref: https://atomiks.github.io/tippyjs/v6/all-props/#animation
    animation: { type: String, default: 'fade' },

    // Interactive determines if the tooltip has interactive content inside of it,
    // so that it can be hovered over and clicked inside without hiding.
    // Ref: https://atomiks.github.io/tippyjs/v6/all-props/#interactive
    interactive: { type: Boolean, default: true },

    // Max width of the tooltip. Numbers in px, can use 'none'.
    maxWidth: { type: Number, default: 485 },

    // Offset format is: [skidding, distance]
    // Ref: https://popper.js.org/docs/v2/modifiers/offset
    offset: { type: Array, default: [0, 10] },

    // Theme.
    theme: { type: String, default: 'pl-data' },

    // Follow the cursor as the cursor moves.
    // Ref: https://atomiks.github.io/tippyjs/#follow-cursor
    followCursor: { type: Boolean, default: false }
  }

  initialize () {
    this.initPopup()
    this.contentTarget.style.display = 'none'
  }

  initPopup () {
    tippy(this.triggerTarget, {
      placement: this.placementValue,
      content: this.contentTarget.innerHTML,
      allowHTML: true,
      // If interactive is to to true, the default behavior is appendTo: "parent"
      appendTo: () => document.body,
      arrow: this.arrowValue,
      duration: this.durationValue,
      interactive: this.interactiveValue,
      maxWidth: this.maxWidthValue,
      offset: this.offsetValue,
      theme: this.themeValue,
      followCursor: this.followCursorValue,
      animation: this.animationValue,
      plugins: [followCursor]
    })
  }
}
