import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="live-countdown"
export default class extends Controller {
  // time: remaining time (ms) in the countdown
  // url: location to redirect to after the countdown reaches zero
  static values = {
    time: Number,
    url: String
  }

  static targets = ['el']

  connect () {
    const refresh = setInterval(() => {
      this.timeValue = Math.max(this.timeValue - 1000, 0)
      this.elTarget.innerHTML = this.str()

      if (this.timeValue === 0) {
        window.location.assign(this.urlValue)
        clearInterval(refresh)
      }
    }, 1000)
  }

  // Countdown string in the format: "HH:MM:SS"
  str () {
    return `${this.#hours()}:${this.#minutes()}:${this.#seconds()}`
  }

  // Hours left, padded with 0s if less than 2 digits.
  #hours () {
    const h = Math.floor(
      (this.timeValue % (1000 * 60 * 60 * 24)) /
      (1000 * 60 * 60)
    )
    return h.toString().padStart(2, '0')
  }

  // Minutes left, padded with 0s if less than 2 digits.
  #minutes () {
    const m = Math.floor(
      (this.timeValue % (1000 * 60 * 60)) /
      (1000 * 60)
    )
    return m.toString().padStart(2, '0')
  }

  // Seconds left, padded with 0s if less than 2 digits.
  #seconds () {
    const s = Math.floor(
      (this.timeValue % (1000 * 60)) /
      1000
    )
    return s.toString().padStart(2, '0')
  }
}
